.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }
  
  @media (min-width: 992px) {
    .rounded-lg-3 { border-radius: .3rem; }
  }


.notificationModalStyling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px !important;
  border-radius: 10px;
  background-color: #FFF;
  padding: 4px;
}
.highlighted-cell {
    background-color: #dcf5ff !important;
}

.data-cell {
    scroll-margin-top: 100px;
  }

.table-header {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
}

.modalStyling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px !important;
  border-radius: 10px;
  background-color: #FFF;
  padding: 4px;
}

.notificationModalStyling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px !important;
  border-radius: 10px;
  background-color: #FFF;
  padding: 4px;
}

.minorIssue {
  background-color: #fff2a9 !important;
}

.majorIssue {
  background-color: #ffcac9 !important;
}